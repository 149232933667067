<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Listado de Proyectos
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="col-6">
          <v-select
              placeholder="Seleccione periodo para obtener proyectos"
              :items="periods"
              :item-text="'name'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Periodo de los proyectos"
              v-model="periodSelected"
              @change="getAllProjectsByPeriod()"
          />
        </v-col>
      </v-row>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :search="search"
          :loading="loading">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de proyectos.</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                label="Buscador"
                placeholder="Buscador"
                class="mx-4"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{index, item}">
          <v-progress-circular
              v-if="item.loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <v-icon v-if="!item.loading" class="mr-2" @click="eraseProject(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ProjectManagment",
  data() {
    return {
      loading: false,
      files: [],
      periods: [],
      periodSelected: new Date().getFullYear(),
      search: "",
      headers: [
        {text: 'Periodo', value: 'createdAt'},
        {text: 'Area', value: 'centroCosto.ccNombre'},
        {text: 'Centro Costo', value: 'centroCosto.ccValor'},
        {text: 'Proyecto', value: 'nombre', width: '30%'},
        {text: 'Acciones', value: 'acciones'},
      ],
      rows: [],
    }
  },
  methods: {
    ...mapActions('main', [
      'getAllProjectsAndApprovalFileLists',
      'deleteProject'
    ]),
    setPeriod() {
      this.periods.push({id: 0, name: "Seleccione"})
      for (let i = 2021; i <= new Date().getFullYear(); i++) {
        this.periods.push({id: i, name: i})
      }
    },
    getAllProjectsByPeriod() {
      this.search = "";
      this.loading = true;
      this.getAllProjectsAndApprovalFileLists(this.periodSelected).then(response => {
        this.rows = [];
        response.data.response.forEach((item) => {
          item.loading = false;
          this.rows.push(item);
        });
        this.loading = false;
      }).catch(error => {
        Swal.fire({
          title: 'Obtener listado de proyectos',
          text: error,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        this.loading = false;
      });
    },
    eraseProject(item) {
      console.log(item);
      Swal.fire({
        title: 'Eliminar proyecto',
        text: "¿Esta seguro que desea eliminar el proyecto " + item.nombre + " con id " + item.id + "?",
        showCancelButton: true,
        confirmButtonColor: '#EA7600',
        confirmButtonText: 'Aceptar',
        icon: 'warning',
      }).then(result => {
        if (result.isConfirmed) {
          this.executeDelete(item.id);
        } else if (result.isDismissed) {
          Swal.fire({title: 'Eliminar proyecto', text: 'El proyecto no será borrado', icon: 'info'});
        }
      });
    },
    executeDelete(id) {
      this.loading = true;
      this.deleteProject(id).then(async response => {
        console.log(response);
        Swal.fire({title: 'Eliminar proyecto', text: "El proyecto " + id + " fue borrado", icon: 'success'});
        await this.getAllProjectsByPeriod();
        this.loading = false;
      }).catch(error => {
        Swal.fire({
          title: 'Eliminar proyecto',
          text: error,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        this.loading = false;
      });
    },
  },
  async created() {
    await this.setPeriod();
    await this.getAllProjectsByPeriod();
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="admin-component mb-8 my-1 py-1 px-4">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title><h3 class="text-start">Centro de Costos</h3></v-card-title>
          <v-tabs>
            <v-tab>
              Activar Centro de Costo
            </v-tab>
            <v-tab>
             Centros de Costo
            </v-tab>
            <v-tab>
             Analistas
            </v-tab>
            <v-tab-item>
              <CostCenterChangeComponent/>
            </v-tab-item>
            <v-tab-item>
              <CostCenteMaintainerComponent/>
            </v-tab-item>
            <v-tab-item>
              <AnalystComponent/>
            </v-tab-item>
          </v-tabs>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CostCenterChange from '@/components/CostCenter/CostCenterChange'
import CostCenterMaintainer from "@/components/CostCenter/CostCenterMaintainer";
import Analyst from "@/components/CostCenter/Analyst";

export default {
  name: "CostCenter",
  components: {
    'CostCenteMaintainerComponent': CostCenterMaintainer,
    'CostCenterChangeComponent': CostCenterChange,
    'AnalystComponent': Analyst
  },
  data() {
    return {
      tabs: null,
    };
  },
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-12 text-left"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="col-4">
          <v-select
              placeholder="Eje Estratégicos"
              :items="ejesEstrategicosFilter"
              :item-text="'nombreEje'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Eje Estratégico"
              @change = "index => fieldSearch(index, 1)"
          />
        </v-col>
        <v-col class="col-4">
          <v-select
              placeholder="Area Misional"
              :items="areasMisionalesFilter"
              :item-text="'nombreArea'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Area Misional"
              @change = "index => fieldSearch(index, 2)"
          />
        </v-col>
      </v-row>
      <v-data-table
          dense
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado completo de estrategias.</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-right">
                  <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear estrategia</v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Crear estrategia</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Eje Estratégico"
                            placeholder="Eje Estratégico"
                            :items="ejeEstrategico"
                            :item-text="'nombreEje'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="estrategia.ejeEstrategico"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Area Misional"
                            placeholder="Area Misional"
                            :items="areaMisional"
                            :item-text="'nombreArea'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="estrategia.areaMisional"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            no-resize
                            label="Estrategia"
                            placeholder="Estrategia"
                            type="text"
                            v-model="estrategia.name"
                            maxlength="200"
                            counter="200"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="estrategia.id === 0" color="blue darken-1" text @click="save()">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="update()">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";
export default {
  name: "StrategyMaintainer",
  data() {
    return {
      rows: [],
      headers: [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Eje Estrategico', value: 'ejeEstrategico.nombreEje', width: '25%' },
        { text: 'Area Misional', value: 'areaMisional.nombreArea', width: '25%' },
        { text: 'Estrategia', value: 'name', width: '25%' },
        { text: "Acciones", value: 'acciones'}
      ],
      ejeEstrategico: [],
      ejesEstrategicosFilter: [],
      areaMisional: [],
      areasMisionalesFilter: [],
      dialog: false,
      loading: false,
      estrategia: {
        id: 0,
        ejeEstrategico: 0,
        areaMisional: 0,
        name: ""
      },
      estrategiaDefault: {
        id: 0,
        ejeEstrategico: 0,
        areaMisional: 0,
        name: ""
      },
      search: ""
    }
  },
  methods: {
    ...mapActions('main', [
      'getAllStrategy',
        'createStrategy',
        'updateStrategy',
        'getAllEjeEstrategico',
        'getAllAreaMisional'
    ]),
    getAllItemsEjeEstrategicosFilter() {
      this.getAllEjeEstrategico().then(response => {
        this.ejesEstrategicosFilter.push({id: 0, nombreEje: "Todos"});
        response.data.response.forEach((item) => {
          this.ejesEstrategicosFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },

    getAllItemsAreasMisionalesFilter() {
      this.getAllAreaMisional().then(response => {
        this.areasMisionalesFilter.push({id: 0, nombreArea: "Todos"});
        response.data.response.forEach((item) => {
          this.areasMisionalesFilter.push(item);
        });
      }).catch(e => {
        console.log(e);
      });
    },
    fieldSearch(index, type){
      switch (type){
        case 1:
          this.fieldSearchEjeEstrategico(index);
          break;
        case 2:
          this.fieldSearchAreaMisional(index);
          break;
      }
    },

    fieldSearchEjeEstrategico(index){
      if(index === 0)
        this.search = "";
      else
        this.search = this.ejesEstrategicosFilter[index].nombreEje;
    },
    fieldSearchAreaMisional(index){
      if(index === 0)
        this.search = "";
      else
        this.search = this.areasMisionalesFilter[index].nombreArea;
    },
    save(){
      this.loading=true;
      this.createStrategy(this.estrategia).then(response => {
        this.getListAllStrategy();
        Swal.fire({
          title: 'Crear Estrategia',
          text: 'Se ha creado la estrategia id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Estrategia',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    update(){
      this.updateStrategy(this.estrategia).then(response => {
        this.getListAllStrategy();
        Swal.fire({
          title: 'Modificar Estrategia',
          text: 'Se ha modificado la estrategia id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Modificar Estrategia',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    edit(item){
      this.estrategia.ejeEstrategico = item.ejeEstrategico.id;
      this.estrategia.areaMisional = item.areaMisional.id;
      this.estrategia.id = item.id;
      this.estrategia.name = item.name;
      this.dialog = true;
    },
    close(){
      this.$nextTick(() => {
        this.estrategia = Object.assign({}, this.estrategiaDefault);
      })
      this.dialog = false;
    },
    getListAllStrategy(){
      this.getAllStrategy().then(response => {
        this.rows = response.data.response;
      }).catch(error => {
        console.log(error);
      });
    },
    getAllItemsEjeEstrategico(){
      this.getAllEjeEstrategico().then(response => {
        this.ejeEstrategico = response.data.response;
      }).catch(error => {
        console.log(error)
      });
    },
    getAllItemsAreaMisionales(){
      this.getAllAreaMisional().then(response => {
        this.areaMisional = response.data.response;
      }).catch(error => {
        console.log(error);
      })

    }
  },
  async created() {
    await this.getListAllStrategy();
    await this.getAllItemsEjeEstrategico();
    await this.getAllItemsAreaMisionales();
    await this.getAllItemsEjeEstrategicosFilter();
    await this.getAllItemsAreasMisionalesFilter();
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Bases Administrativas por Periodo
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de Bases Administrativas por Periodo</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Subir Bases Administrativas</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Bases Administrativas por Periodo</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            :items="periods"
                            :item-text="'name'"
                            :item-value="'id'"
                            v-model="administrativeBases.period"
                            label="Seleccione Periodo"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                            chips
                            counter
                            show-size
                            accept=".pdf"
                            truncate-length="50"
                            v-model="administrativeBases.file"
                            label="Seleccione el pdf de bases administrativas"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="number"
                            v-model="administrativeBases.pages"
                            label="Paginas en documento de bases administrativas"
                            placeholder="Paginas"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn color="blue darken-1" text @click="save()">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{index, item}">
          <v-progress-circular
              v-if="item.loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <v-icon v-if="!item.loading" class="mr-3"  @click="downloadFile(index, item)">mdi-download</v-icon>
          <v-icon v-if="!item.loading"  @click="preDelete(item)">mdi-delete-outline</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import {mapActions} from "vuex";

export default {
  name: "AdministrativeBasesMaintainer",
  data() {
    return {
      periods: [],
      rows: [],
      headers: [
        { text: 'Nombre', value: 'filename' },
        { text: 'Tipo', value: 'mime' },
        { text: 'Periodo', value: 'period' },
        { text: 'Acciones', value: 'acciones' },
      ],
      administrativeBases: {
        id: 0,
        period: 0,
        file: {},
        pages: 0,
        loading: false
      },
      defaultAdministrativeBases: {
        id: 0,
        period: 0,
        file: {},
        pages: 0,
        loading: false
      },
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions('main', [
      'saveAdministrativeBases',
        'getAllAdministrativeBases',
        'downloadAdministrativeBase',
        'deleteAdministrativeBase'
    ]),
    setPeriod(){
      for (let i = 2021; i <= 2050; i++){
        this.periods.push({id: i, name: i})
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.reportItem = Object.assign({}, this.defaultAdministrativeBases)
      })
    },
    save(){
      this.saveAdministrativeBases(this.administrativeBases).then(response => {
        console.log(response.data);
        this.close();
        this.list();
        Swal.fire({
          title: 'Crear Bases Administrativas',
          text: 'Se ha bases administrativas id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Bases Administrativas',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'error',
        })
        this.loading = false;
      });
    },
    preDelete(item){
      Swal.fire({
        icon: "question",
        title: 'Importante:',
        text: 'Una vez eliminado, no lo prodrá volver a acceder. ¿Desea continuar ..?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Eliminar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(item)
        } else if (result.isDenied) {
          Swal.fire('Base Administrativa no será eliminada.', '', 'info')
        }
      })
    },
    delete(item){
      item.loading = true;
      this.deleteAdministrativeBase(item.id).then(response => {
        this.list();
        console.log(response);
        Swal.fire({
          title: 'Eliminar Base Administrativa',
          text: 'Se ha eliminado la base Administrativa' + item.filename,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Eliminar Base Administrativa',
          text: e.response.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        item.loading = false;
      });
    },
    list(){
      this.rows = [];
        this.getAllAdministrativeBases().then(response => {
          response.data.response.forEach((item) => {
            item.loading = false;
            this.rows.push(item);
          })
        }).catch(e => {
          console.log(e);
        });
    },
    downloadFile(index, item) {
      console.log(index, item);
      this.downloadAdministrativeBase(item.id)
          .then(response => {
            this.download(response.data.response);
          })
          .catch(err => {
            console.log(err.response);
            Swal.fire({
              title: 'Obtener archivo de bases administrativas',
              text: err,
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              icon: 'warning',
            });
          });
    },
    download(data){
      const {content, filename, mime} = data;
      const linkSource = `data:${mime};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    },
  },
  async created() {
    this.setPeriod();
    await this.list();
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-12 text-left"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :loading="loading"
          loading-text="Cargando... por favor espere"
          dense
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de Centros de Costo</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-right">
                  <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear Centro de Costo</v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Crear Centro de Costo</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="centroCosto.name"
                            label="Nombre Centro de Costo"
                            placeholder="Nombre Centro de Costo"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            v-if="!centroCosto.automaticNumber"
                            type="text"
                            v-model="centroCosto.costCenter"
                            label="Numero Centro de Costo"
                            placeholder="Numero Centro de Costo"
                        ></v-text-field>
                        <v-checkbox
                            v-model="centroCosto.automaticNumber"
                            :label="`Numeración Automatica`"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            placeholder="Seleccione Analista asignado al centro de costo"
                            :items="analysts.list"
                            :item-text="'name'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            label="Analista asignado al Centro de Costo"
                            v-model="centroCosto.analystId"
                            :loading=analysts.loading
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            placeholder="Seleccione Tipo de Centro de Costo"
                            :items="centroCostoTipo"
                            :item-text="'name'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            label="Tipo de Centro de Costo"
                            v-model="centroCosto.type"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            v-if="!centroCosto.mayor"
                            placeholder="Seleccione Centro de Costo Mayor al cual pertenece"
                            :items="units.mayorList"
                            :item-text="'ccNombre'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            label="Centro de Costo Mayor al cual pertenece"
                            v-model="centroCosto.mayorId"
                            :loading="units.loading"
                        />
                        <v-checkbox
                            v-model="centroCosto.mayor"
                            :label="`Es mayor`"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="col-12">
                        <v-checkbox
                            v-model="centroCosto.active"
                            :label="`Activo`"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="centroCosto.id === 0" color="blue darken-1" text @click="checkAndSave()" :loading="loading">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="checkAndUpdate()" :loading="loading">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.active="{item}">
          <span v-if="item.active">activo</span>
          <span v-else>inactivo</span>
        </template>
        <template v-slot:item.esMayor="{item}">
          <span v-if="item.esMayor">SI</span>
          <span v-else>NO</span>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import {mapActions} from "vuex";

export default {
  name: "CostCenterMaintainer",
  data(){
    return {
      loading: false,
      dialog: false,
      rows: [],
      analysts: {
        list: [],
        loading: false,
      },
      units: {
        mayorList: [],
        loading: false
      },
      headers: [
        { text: 'Centro de Costo', value: 'ccValor' },
        { text: 'Nombre', value: 'ccNombre' },
        { text: 'Tipo Unidad', value: 'tipoUnidad' },
        { text: 'Mayor', value: 'esMayor' },
        { text: 'Nombre Analista', value: 'analista.name' },
        { text: 'Email Analista', value: 'analista.email' },
        { text: 'Estado', value: 'active' },
        {text: 'Acciones', value: "acciones"},
      ],
      centroCosto: {
        id: 0,
        name: "",
        costCenter: 0,
        automaticNumber: false,
        mayor: false,
        mayorId: 0,
        analystId: 0,
        type: "",
        active: true
      },
      centroCostoDefault: {
        id: 0,
        name: "",
        costCenter: 0,
        automaticNumber: false,
        mayor: false,
        mayorId: 0,
        analystId: 0,
        type: "",
        active: true
      },
      centroCostoTipo: [
        {'id': "Administrativa", 'name': "Administrativa"},
        {'id': "Académica", 'name': "Académica"},
      ]
    }
  },
  methods: {
    ...mapActions('main', [
      'serviceCostCenterList',
      'serviceCostCenterMayor',
      'serviceCostCenterCreate',
      'serviceCostCenterUpdate',
      'serviceAnalystListActive'
    ]),
    getMayorUnits(){
      this.units.mayorList = [];
      this.units.loading = true;
      this.serviceCostCenterMayor().then(response => {
        this.units.mayorList = response.data.response;
        this.units.loading = false;
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Obtener listado de Analistas',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.units.loading = false;
      });
    },
    getActiveAnalyst(){
      this.analysts.list = [];
      this.analysts.loading = true;
      this.serviceAnalystListActive().then(response => {
        this.analysts.list = response.data.response;
        this.analysts.loading = false;
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Obtener listado de Analistas',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.analysts.loading = false;
      });
    },
    update(){
      this.loading=true;
      this.checkCentroCostoMayor();
      this.serviceCostCenterUpdate(this.centroCosto).then(() => {
        this.list();
        Swal.fire({
          title: 'Actualizar Centro de Costo',
          text: 'Se ha actualizado el Centro de Costo: ' + this.centroCosto.name,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Actualizar Centro de Costo',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    edit(item){
      this.centroCosto.id = item.id;
      this.centroCosto.name = item.ccNombre;
      this.centroCosto.costCenter = item.ccValor;
      this.centroCosto.active = item.active;
      if(item.analista !== null)
        this.centroCosto.analystId = item.analista.id;
      this.centroCosto.type = item.tipoUnidad;
      this.centroCosto.mayor = item.esMayor;
      this.centroCosto.mayorId = item.centroMayor;
      this.dialog = true
    },
    list(){
      this.loading=true;
      this.rows = [];
      this.serviceCostCenterList().then(response => {
        response.data.response.forEach((item) => {
          item.loading = false;
          this.rows.push(item);
        })
        this.loading=false;
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Obtener listado de Centros de Costo',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    save(){
      this.loading=true;
      this.checkCentroCostoMayor();
      this.serviceCostCenterCreate(this.centroCosto).then(() => {
        this.list();
        Swal.fire({
          title: 'Crear Centro de Costo',
          text: 'Se ha creado el Centro de Costo: ' + this.centroCosto.name,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Centro de Costo',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    checkAndSave() {
      this.save();
    },
    checkAndUpdate() {
      this.update();
    },
    close(){
      this.dialog = false;
      this.loading = false;
      this.reset();
    },
    reset(){
      this.$nextTick(() => {
        this.centroCosto = Object.assign({}, this.centroCostoDefault)
      })
    },
    checkCentroCostoMayor(){
      if(this.centroCosto.mayor){
        this.centroCosto.mayorId = null;
      }
    },
  },
  async created() {
    await this.list();
    await this.getActiveAnalyst();
    await this.getMayorUnits();
  }
}
</script>

<style scoped>

</style>
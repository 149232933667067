<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Evaluaciones de Periodos
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de Evaluaciones por periodo</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear Evaluación</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Evaluaciones</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="evaluacion.name"
                            label="Nombre Evaluación"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="evaluacion.description"
                            label="Descripción de la evaluación"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            :items="periods"
                            :item-text="'name'"
                            :item-value="'id'"
                            v-model="evaluacion.period"
                            label="Seleccione Periodo"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="evaluacion.id === 0" color="blue darken-1" text @click="save()">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="update()">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions} from 'vuex'
import Swal from "sweetalert2";
export default {
  name: "EvaluationMaintainer",
  data(){
    return {
      rows: [],
      loading: false,
      dialog: false,
      periods: [],
      headers: [
        {
          text: 'Codigo',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Evaluación', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: 'Periodo', value: 'period' },
        { text: "Acciones", value: 'acciones'}
      ],
      evaluacion: {
        id: 0,
        name: "",
        description: "",
        period: 0,
      },
      defaultEvaluacion: {
        id: 0,
        name: "",
        description: "",
        period: 0,
      },
      setPeriods(){
        for (let i = 2020; i <= new Date().getFullYear(); i++){
          this.periods.push({id: i, name: i})
        }
      },
    }
  },
  methods: {
    ...mapActions('main', [
      'getAllEvaluation',
      'createEvaluation',
      'updateEvaluation',
    ]),
    edit(item) {
      this.evaluacion.id = item.id;
      this.evaluacion.name = item.name;
      this.evaluacion.description = item.description;
      this.evaluacion.period = item.period;
      this.dialog = true
    },
    getAllItemsEvaluation () {
      this.getAllEvaluation().then(response => {
        this.rows = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.evaluacion = Object.assign({}, this.defaultEvaluacion)
      })
    },
    save () {
      this.loading=true;
      this.createEvaluation(this.evaluacion).then(response => {
        this.getAllItemsEvaluation();
        Swal.fire({
          title: 'Crear Evaluación',
          text: 'Se ha creado la evaluación id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Evaluación',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    update () {
      this.updateEvaluation(this.evaluacion).then(response => {
        this.getAllItemsEvaluation();
        Swal.fire({
          title: 'Modificar Evaluación',
          text: 'Se ha modificado la evaluación id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Modificar Evaluación',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
  },
  async created(){
    await this.setPeriods();
    await this.getAllItemsEvaluation();
  }
}
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"col-10 text-left"},[_vm._v(" Informes Generales para Administrador ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('v-select',{attrs:{"placeholder":"Seleccione periodo para generar informe","items":_vm.periodos,"item-text":'name',"item-value":'id',"icon":"mdi-arrow-bottom-right-thin-circle-outline","label":"Periodo del informe"},model:{value:(_vm.periodoSeleccionado),callback:function ($$v) {_vm.periodoSeleccionado=$$v},expression:"periodoSeleccionado"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":this.headers,"items":this.fichas,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Tabla con Fichas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.periodoSeleccionado)+" ")]}},{key:"item.download",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!item.loading)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.downloadFichas(index, item)}}},[_vm._v("mdi-download")]):_vm._e()]}}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":this.headers,"items":this.uci,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Tabla con informes UCI")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.periodoSeleccionado)+" ")]}},{key:"item.download",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!item.loading)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.downloadUci(index, item)}}},[_vm._v("mdi-download")]):_vm._e()]}}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":this.headers,"items":this.centrocosto,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Tabla con Informes de Avance")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.periodoSeleccionado)+" ")]}},{key:"item.download",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!item.loading)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.downloadCentroCosto(index, item)}}},[_vm._v("mdi-download")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-12 text-left"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          dense
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :loading="loading"
          loading-text="Cargando... por favor espere">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de Analistas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-right">
                  <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear Analista</v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Crear Analista</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="analista.name"
                            label="Nombre"
                            placeholder="Nombres del Analista"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="email"
                            v-model="analista.email"
                            label="Email"
                            placeholder="Email del Analista"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="col-12">
                        <v-checkbox
                            v-model="analista.active"
                            :label="`Activo`"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-row>
                    <v-col>
                      <span v-if="errors.length" class="red--text">
                        <b>Favor verificar y resolver error(es):</b>
                      <ul>
                        <li v-for="error in errors" v-bind:key="error.key">{{ error }}</li>
                      </ul>
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="analista.id === 0" color="blue darken-1" text @click="checkAndSave()" :loading="loading">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="checkAndUpdate()" :loading="loading">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.active="{item}">
          <span v-if="item.active">activo</span>
          <span v-else>inactivo</span>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import {mapActions} from "vuex";

export default {
  name: "Analyst",
  data() {
    return {
      loading: false,
      errors: [],
      dialog: false,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Estado', value: 'active'},
        { text: 'Acciones', value: 'acciones' },
      ],
      rows: [],
      analista: {
        id: 0,
        name: "",
        email: "",
        active: true
      },
      analistaDefault: {
        id: 0,
        name: "",
        email: "",
        active: true
      }
    }
  },
  async created() {
    await this.list();
  },
  methods: {
    ...mapActions('main', [
      'serviceAnalystStore',
      'serviceAnalystUpdate',
        'serviceAnalystList'
    ]),
    edit(item){
      this.analista.id = item.id;
      this.analista.name = item.name;
      this.analista.email = item.email;
      this.analista.active = item.active;
      this.dialog = true
    },
    list(){
      this.rows = [];
      this.serviceAnalystList().then(response => {
        response.data.response.forEach((item) => {
          item.loading = false;
          this.rows.push(item);
        })
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Obtener listado de Analistas',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    update(){
      this.loading=true;
      this.serviceAnalystUpdate(this.analista).then(response => {
        console.log(response.data.response)
        this.list();
        Swal.fire({
          title: 'Actualizar Analista',
          text: 'Se ha actualizado el Analista: ' + this.analista.name,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Actualizar Analista',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    save(){
      this.loading=true;
      this.serviceAnalystStore(this.analista).then(response => {
        console.log(response.data.response);
        this.list();
        Swal.fire({
          title: 'Crear Analista',
          text: 'Se ha creado el Analista: ' + this.analista.name,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Analista',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    checkAndSave(){
      if(this.checkFormIsOK()){
        this.save();
      }
    },
    checkFormIsOK: function () {
      this.errors = [];
      if (!this.analista.name) {
        this.errors.push("Nombre es obligatorio.");
      }
      if (!this.analista.email) {
        this.errors.push('Email es obligatorio.');
      } else if (!this.validEmail(this.analista.email)) {
        this.errors.push('Es necesario un Email válido.');
      }
      if (!this.errors.length) {
        return true;
      }
    },
    checkAndUpdate(){
      if(this.checkFormIsOK()){
        this.update();
      }
    },
    close(){
      this.dialog = false;
      this.loading = false;
      this.reset();
    },
    reset(){
      this.$nextTick(() => {
        this.analista = Object.assign({}, this.analistaDefault)
      })
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="mb-8 my-16 py-6 px-4">
    <v-tabs v-model="tabs">
      <v-tab><v-icon left>
        mdi-playlist-edit
      </v-icon>Evaluaciones</v-tab>
      <v-tab><v-icon left>
        mdi-playlist-edit
      </v-icon>Preguntas por Evaluación</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <EvaluationMaintainerComponent/>
      </v-tab-item>
      <v-tab-item>
        <QuestionMaintainerComponent/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import EvaluationMaintainer from './EvaluationMaintainer'
import QuestionMaintainer from '../Question/QuestionMaintainer'
export default {
  name: "Evaluations",
  components: {
    'EvaluationMaintainerComponent': EvaluationMaintainer,
    'QuestionMaintainerComponent': QuestionMaintainer
  },
  data() {
    return {
      tabs: null,
    };
  },
};
</script>

<style scoped>
</style>
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Administración de Usuarios
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :search="search"
          :loading="loading">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de usuarios.</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
                type="text"
                v-model="search"
                label="Buscar"
                placeholder="Busca en todas las columnas de la tabla"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear Usuario</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Usuario</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="users.nombre"
                            label="Nombre Usuario"
                            placeholder="Nombre del usuario que desea registrar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                          <v-text-field
                              type="text"
                              v-model="users.email"
                              label="Email de Usuario"
                              placeholder="Email del usuario que desea registrar"
                          ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            :items="tipoUsuario"
                            :item-text="'name'"
                            :item-value="'id'"
                            v-model="users.typeUser"
                            label="Seleccione Tipo de Usuario"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                            :items="centroCostos"
                            :item-text="'ccNombre'"
                            :item-value="'id'"
                            v-model="users.centroCosto"
                            label="Seleccione Centro de Costo"
                            filled
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            :items="periodos"
                            :item-text="'name'"
                            :item-value="'id'"
                            v-model="users.periodoActivo"
                            label="Seleccione Periodo Activo"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="users.id <= 0" color="blue darken-1" text @click="store()">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="update()">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="preResetPassword(item)">mdi-lock</v-icon>
          <v-icon small class="mr-2" @click="preDeleteUser(item)">mdi-delete</v-icon>
        </template>
        </v-data-table>
    </v-card-text>
  </v-card>

</template>

<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "UsersManagement",
  data(){
    return{
      headers:[
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Email', value: 'email' },
        { text: 'Tipo Usuario', value: 'typeUser' },
        { text: 'Centro Costo', value: 'centroCosto.ccNombre' },
        { text: 'Periodo Actual', value: 'periodoActivo' },
        { text: 'Creado', value: 'createdAt' },
        { text: 'Acciones', value: 'acciones' },
      ],
      rows: [],
      search: "",
      loading: false,
      dialog: false,
      centroCostos: [],
      periodos: [],
      tipoUsuario:[
        {id: "sin asignar", name: "Sin Asignar"},
        {id: "dge", name: "Dge"},
        {id: "dca", name: "Dca"},
        {id: "prorrector", name: "Prorrector"},
        {id: "rector", name: "Rector"},
        {id: "normal", name: "Normal"},
        {id: "vicerrector", name: "Vicerrector"},
        {id: "director", name: "Director"},
      ],
      users: {
        id:0,
        nombre:"",
        email: "",
        resetPassword: false,
        typeUser: "",
        centroCosto: 0,
        periodoActivo: 0
      },
      defaultUsers: {
        id:0,
        nombre:"",
        email: "",
        resetPassword: false,
        typeUser: "",
        centroCosto: 0,
        periodoActivo: 0
      }
    }
  },
  filters: {
    trim: function(string) {
      return string.trim()
    }
  },
  methods:{
    ...mapActions('main', [
      'listUsers',
      'storeUsers',
      'deleteUsers',
      'resetPasswordUsers',
      'updateUsers',
      'getAllCostCenter',
      'serviceCostCenterActivos',
    ]),
    setPeriodos(){
      for (let i = 2020; i <= new Date().getFullYear(); i++){
        this.periodos.push({id: i, name: i})
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.users = Object.assign({}, this.defaultUsers)
      })
    },
    edit(item){
      this.users.id = item.id;
      this.users.nombre = item.nombre;
      this.users.email = this.$options.filters.trim(item.email);
      this.users.typeUser = item.typeUser;
      this.users.periodoActivo = item.periodoActivo;
      this.users.centroCosto = item.centroCosto.id;
      this.dialog = true;
    },
    list(){
      this.loading = true;
      this.listUsers()
          .then(response => {
            this.rows = response.data.response
            this.loading = false;
          })
          .catch(err => {
            console.log(err.response);
            Swal.fire({
              title: 'Obtener listado de usuarios',
              text: err.response.message,
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              icon: 'warning',
            });
            this.loading = false;
          });
    },
    store(){
      this.loading=true;
      this.storeUsers(this.users).then(response => {
        this.list();
        Swal.fire({
          title: 'Crear usuario',
          text: 'Se ha creado el usuario id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Usuario',
          text: e.response.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    preResetPassword(item){
      Swal.fire({
        icon: "question",
        title: 'Importante:',
        text: 'Se modificará la password al valor inicial. ¿Desea continuar ..?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Resetear',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetPassword(item)
        } else if (result.isDenied) {
          Swal.fire('Password no será modificada.', '', 'info')
        }
      })
    },
    resetPassword(item){
      this.loading = true;
      this.resetPasswordUsers(item.id).then(response => {
        console.log(response);
        Swal.fire({
          title: 'Reset password de usuario',
          text: 'Se ha reseteado la password del usuario ' + item.email,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.loading = false;
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Reset password de usuario',
          text: e.response.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        this.loading = false;
      });
    },
    preDeleteUser(item){
      Swal.fire({
        icon: "question",
        title: 'Importante:',
        text: 'Una vez eliminado, no podrá volver a modificar este usuario. ¿Desea continuar ..?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Eliminar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUser(item)
        } else if (result.isDenied) {
          Swal.fire('Usuario no será eliminado.', '', 'info')
        }
      })
    },
    deleteUser(item){
      this.loading = true;
      this.deleteUsers(item.id).then(response => {
        this.list();
        console.log(response);
        Swal.fire({
          title: 'Eliminar usuario',
          text: 'Se ha eliminado el usuario ' + item.email,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Eliminar Usuario',
          text: e.response.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        this.loading = false;
      });
    },
    update(){
      this.loading=true;
      this.updateUsers(this.users).then(response => {
        this.list();
        Swal.fire({
          title: 'Actualizar usuario',
          text: 'Se ha actualizado el usuario id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Actualizar Usuario',
          text: e.response.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    listCentroCostos(){
      this.serviceCostCenterActivos().then(response => {
        this.centroCostos = response.data.response;
      }).catch(err => {
        console.log(err);
        Swal.fire({
          title: 'Listado centros de costos',
          text: err.response.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },

  },
  async created() {
    await this.listCentroCostos();
    await this.setPeriodos();
    await this.list();
  }
}
</script>
<style scoped>
</style>
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Archivos de Aprobacion de proyectos
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="col-6">
          <v-select
              placeholder="Seleccione periodo para generar informe"
              :items="periods"
              :item-text="'name'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Periodo de los proyectos"
              v-model="periodSelected"
              @change="getAllProjectsByPeriodsAndApprovalFileLists()"
          />
        </v-col>
      </v-row>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :search="search"
          :loading="loading">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de archivos de aprobacion de proyectos.</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                label="Buscador"
                placeholder="Buscador"
                class="mx-4"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
                :loading="loading"
                style="background-color: #EA7730;
                color: white" dark class="mr-md-1"
            @click="downloadAllFiles()">
              Descargar Todo Zip
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{index, item}">
          <v-progress-circular
              v-if="item.loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <v-icon v-if="!item.loading" class="mr-2" @click="downloadFile(item)">mdi-download</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>

import {mapActions} from "vuex";
import Swal from "sweetalert2";
import JsZip from 'jszip'

export default {
  name: "AdminApprovalFiles",
  data(){
    return{
      loading: false,
      files: [],
      periods: [],
      periodSelected: new Date().getFullYear(),
      search: "",
      headers: [
        { text: 'Periodo', value: 'createdAt' },
        { text: 'Area', value: 'centroCosto.ccNombre' },
        { text: 'CCosto', value: 'centroCosto.ccValor' },
        { text: 'Proyecto', value: 'nombre', width: '30%' },
        { text: 'Archivo', value: 'documentos[0].nombre' },
        { text: 'Acciones', value: 'acciones' },
      ],
      rows: [],
    }
  },
  methods:{
  ...mapActions('main', [
      'getAllProjectsAndApprovalFileLists',
      'downloadDocumentoProject'
    ]),
    setPeriod(){
      this.periods.push({id: 0, name: "Seleccione"})
      for (let i = 2021; i <= new Date().getFullYear(); i++){
        this.periods.push({id: i, name: i})
      }
    },
    getAllProjectsByPeriodsAndApprovalFileLists(){
    this.search = "";
    this.loading = true;
      this.getAllProjectsAndApprovalFileLists(this.periodSelected).then(response => {
        this.rows = [];
        response.data.response.forEach((item) => {
          item.loading = false;
          this.rows.push(item);
        })
        this.loading = false;
      }).catch(error => {
        Swal.fire({
          title: 'Obtener listado de proyectos',
          text: error,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        this.loading = false;
      });
    },
    downloadFile(item) {
    item.loading = true;
      this.downloadDocumentoProject(item.id)
          .then(response => {
            this.download(response.data.response);
            item.loading = false;
          })
          .catch(err => {
            console.log(err.response);
            Swal.fire({
              title: 'Obtener listado de proyectos',
              text: err,
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              icon: 'warning',
            });
            item.loading = false;
          });
    },
    download(data){
      const {content, filename, mime} = data;
      const linkSource = `data:${mime};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    },
    downloadAllFiles(){
      let files = [];
      let zip = new JsZip();
      this.rows.map(async element => {
        this.loading = true;
        if (element.documentos.length > 0) {
          files.push(this.downloadDocumentoProject(element.id))
        }
      });
      Promise.all(files).then((response)=>{
        response.forEach((item) => {
          zip.file(item.data.response.filename, item.data.response.content, {base64: true});
        });
        zip.generateAsync({type: "blob"}).then(r => {
          let a = document.createElement("a");
          a.href = URL.createObjectURL(r);
          a.download = "archivos aprobacion proyectos "+this.periodSelected+".zip";
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        }).catch(e =>{console.log(e); this.loading = false;})
      });
    }
  },
  async created() {
    await this.setPeriod();
    await this.getAllProjectsByPeriodsAndApprovalFileLists();
  }
}
</script>

<style scoped>

</style>
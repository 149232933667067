<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Ejes estratégicos asociados a planes transversales
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="rows"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de ejes estratégicos asociados a planes transversales</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Asociar Eje a Plan</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Ejes Transversales asociados a Plan Transversal</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            placeholder="Plan Transversal"
                            :items="planes"
                            :item-text="'name'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="filterEjePlan.planTransversal"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            placeholder="Eje Estratégico"
                            :items="ejes"
                            :item-text="'nombreEje'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="filterEjePlan.ejeEstrategico"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="filterEjePlan.id === 0" color="blue darken-1" text @click="save()">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="update()">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="checkDelete(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions} from 'vuex';
import Swal from "sweetalert2";
export default {
  name: "TransversalPlanJoinStrategicAxis",
  data(){
    return {
      loading: false,
      dialog: false,
      rows:[],
      headers:[
        { text: 'Plan Transversal', value: 'planTransversal.name' },
        { text: 'Eje Estratégico', value: 'ejeEstrategico.nombreEje' },
        { text: "Acciones", value: 'acciones'}
      ],
      filterEjePlan:{
        id: 0,
        planTransversal: 0,
        ejeEstrategico: 0
      },
      defaultFilterEjePlan:{
        id: 0,
        planTransversal: 0,
        ejeEstrategico: 0
      },
      planes: [],
      ejes: [],
    }
  },
  methods:{
    ...mapActions('main', [
      'getAllPlanesTransversales',
      'getAllEjeEstrategico',
      'getAllFilterEjesPlanes',
      'joinPlanTransversalEjeEstrategico',
      'deleteEjeEstrategicoPlanTranversal'
    ]),
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.filterEjePlan = Object.assign({}, this.defaultFilterEjePlan)
      })
    },
    getAllItemsPlanesTransversales () {
      this.getAllPlanesTransversales().then(response => {
        this.planes = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    getAllItemsEjeEstrategico () {
      this.getAllEjeEstrategico().then(response => {
        this.ejes = response.data.response;
      })
          .catch(e => {
            console.log(e);
          });
    },
    getAllItemsFilterEjesPlanes () {
      this.getAllFilterEjesPlanes().then(response => {
        this.rows = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    save () {
      this.loading=true;
      this.joinPlanTransversalEjeEstrategico(this.filterEjePlan).then(response => {
        this.getAllItemsFilterEjesPlanes();
        Swal.fire({
          title: 'Crear Plan Transversal',
          text: 'Se ha creado el Plan Transversal id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Plan Transversal',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    checkDelete(item){
      console.log(item);
      Swal.fire({
        title: 'Eliminar Eje Estrategico de Plan Transversal',
        text: "¿Esta seguro que desea eliminar el Eje Estrategico: "+ item.ejeEstrategico.nombreEje +" del Plan Transversal: " + item.planTransversal.name,
        showCancelButton: true,
        confirmButtonColor: '#EA7600',
        confirmButtonText: 'Aceptar',
        icon: 'warning',
      }).then(result => {
        if (result.isConfirmed) {
          this.executeDelete(item.id);
        } else if (result.isDismissed) {
          Swal.fire({title: 'Eliminar Eje Estrategico de Plan Transversal', text: 'El Eje Estrategico del Plan Transversal no será borrado', icon: 'info'});
        }
      });
    },
    executeDelete(id) {
      this.loading = true;
      this.deleteEjeEstrategicoPlanTranversal(id).then(async response => {
        console.log(response);
        Swal.fire({title: 'Eliminar Eje Estrategico de Plan Transversal', text: "El Eje Estrategico de Plan Transversal " + id + " fue borrado", icon: 'success'});
        await this.getAllItemsFilterEjesPlanes();
        this.loading = false;
      }).catch(error => {
        Swal.fire({
          title: 'Eliminar Eje Estrategico de Plan Transversal',
          text: error,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        });
        this.loading = false;
      });
    }
  },
  async created() {
    await this.getAllItemsEjeEstrategico();
    await this.getAllItemsPlanesTransversales();
    await this.getAllItemsFilterEjesPlanes();
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Informes Generales para Administrador
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="col-6">
          <v-select
              placeholder="Seleccione periodo para generar informe"
              :items="periodos"
              :item-text="'name'"
              :item-value="'id'"
              icon="mdi-arrow-bottom-right-thin-circle-outline"
              label="Periodo del informe"
              v-model="periodoSeleccionado"
          />
        </v-col>
      </v-row>
      <v-data-table
          :headers="this.headers"
          :items="this.fichas"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con Fichas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{index, item}">
          {{item.name}} {{periodoSeleccionado}}
        </template>
        <template v-slot:item.download="{index, item}">
          <v-progress-circular
              v-if="item.loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <v-icon v-if="!item.loading" class="mr-2" @click="downloadFichas(index, item)">mdi-download</v-icon>
        </template>
      </v-data-table>

      <v-data-table
          :headers="this.headers"
          :items="this.uci"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con informes UCI</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{index, item}">
          {{item.name}} {{periodoSeleccionado}}
        </template>
        <template v-slot:item.download="{index, item}">
          <v-progress-circular
              v-if="item.loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <v-icon v-if="!item.loading" class="mr-2" @click="downloadUci(index, item)">mdi-download</v-icon>
        </template>
      </v-data-table>

      <v-data-table
          :headers="this.headers"
          :items="this.centrocosto"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con Informes de Avance</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{index, item}">
          {{item.name}} {{periodoSeleccionado}}
        </template>
        <template v-slot:item.download="{index, item}">
          <v-progress-circular
              v-if="item.loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <v-icon v-if="!item.loading" class="mr-2" @click="downloadCentroCosto(index, item)">mdi-download</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";
export default {
  name: "AdminReports",
  data(){
    return{
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Formato', value: 'format' },
        { text: 'Descargar', value: 'download' },
      ],
      fichas: [
        {name: 'Fichas ejes de proyectos', format: 'Excel', loading: false},
        {name: 'Fichas hitos de proyectos', format: 'Excel', loading: false},
        {name: 'Fichas gastos de proyectos', format: 'Excel', loading: false},
      ],
      uci: [
        {name: 'Informe de avance de montos solicitados', format: 'Excel', loading: false},
      ],
      centrocosto: [
        {name: 'Informe de avance evaluación de proyectos', format: 'Excel' ,loading: false},
        {name: 'Informe de avance de hitos', format: 'Excel', loading: false},
        {name: 'Informe avance de aprobación y carta por proyectos', format: 'Excel', loading: false},
        {name: 'Informe iniciativas de gobierno central', format: 'Excel', loading: false},
        {name: 'Informe estado de proyectos por centros de costo', format: 'Excel' ,loading: false},
      ],
      periodos: [],
      periodoSeleccionado: new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions('main', [
      'getExcelAdvanceProjectAmount',
      'getExcelAdvanceProjectEvaluation',
      'getExcelAdvanceProjectMilestones',
      'getExcelProjectItemsExpenses',
      'getExcelProjectMilestones',
      'getExcelProjectEjes',
      'getExcelApprovalProjectLetters',
      'getExcelIniciativas',
      'serviceReportsCostCenterProjectLess'
    ]),
    setPeriod(){
      this.periodos.push({id: 0, name: "Seleccione"})
      for (let i = 2021; i <= new Date().getFullYear(); i++){
        this.periodos.push({id: i, name: i})
      }
    },
    downloadProjectEjes(item){
      item.loading = true;
      this.getExcelProjectEjes(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadProjectMilestone(item){
      item.loading = true;
      this.getExcelProjectMilestones(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadProjectItemsExpenses(item){
      item.loading = true;
      this.getExcelProjectItemsExpenses(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadIniciativas(item){
      item.loading = true;
      this.getExcelIniciativas().then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadAdvanceProjectAmount(item){
      item.loading = true;
      this.getExcelAdvanceProjectAmount(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadAdvanceProjectEvaluation(item){
      item.loading = true;
      this.getExcelAdvanceProjectEvaluation(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadAdvanceProjectMilestone(item){
      item.loading = true;
      this.getExcelAdvanceProjectMilestones(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadAdvanceApprovalProjectLetters(item) {
      item.loading = true;
      this.getExcelApprovalProjectLetters(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    downloadCostCenterProjectLess(item){
      item.loading = true;
      this.serviceReportsCostCenterProjectLess(this.periodoSeleccionado).then(response => {
        this.download(response);
        item.loading = false;
      }).catch(async err => {
        let error = await err.response.data.message;
        console.log(error);
        this.messageFailRequest(error);
        item.loading = false;
      });
    },
    messageFailRequest(error){
      Swal.fire({
        title: 'Descargar Plantilla Excel',
        text: error,
        confirmButtonColor: '#EA7600',
        confirmButtonText: 'Aceptar',
        icon: 'warning',
      })
    },
    download(response){
      const {content, filename, mime} = response.data.response;
      const linkSource = `data:${mime};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    },
    downloadFichas(index, item){
      switch (index){
        case 0:
          this.downloadProjectEjes(item);
        break;
        case 1:
          this.downloadProjectMilestone(item);
          break;
        case 2:
          this.downloadProjectItemsExpenses(item);
          break;
      }
    },
    downloadUci(index, item){
      switch (index){
        case 0:
          this.downloadAdvanceProjectAmount(item);
          break;
      }
    },
    downloadCentroCosto(index, item){
      switch (index){
        case 0:
          this.downloadAdvanceProjectEvaluation(item);
          break;
        case 1:
          this.downloadAdvanceProjectMilestone(item);
          break;
        case 2:
          this.downloadAdvanceApprovalProjectLetters(item);
          break;
        case 3:
          this.downloadIniciativas(item);
          break;
        case 4:
          this.downloadCostCenterProjectLess(item)
      }
    },
  },
  created() {
    this.setPeriod();
  }
}
</script>
<style scoped>
</style>
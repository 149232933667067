<template>
  <div class="admin-component mb-8 my-1 py-1 px-4">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title><h3 class="text-start">Panel de Administrador</h3></v-card-title>
          <v-tabs>
            <v-tab>
              Centro de Costo
            </v-tab>
            <v-tab>
              Evaluacion de Proyecto
            </v-tab>
            <v-tab>
              Planes Transversales
            </v-tab>
            <v-tab>
              Estrategias
            </v-tab>
            <v-tab>
              Informes de Administrador
            </v-tab>
            <v-tab>
              Bases Administrativas
            </v-tab>
            <v-tab>
              Archivos de Aprobación
            </v-tab>
            <v-tab>
              Proyectos
            </v-tab>
            <v-tab>
              Usuarios
            </v-tab>
<!--            <v-tab>-->
<!--                Formularios Plataforma-->
<!--            </v-tab>-->
            <v-tab-item>
              <CostCenterComponent/>
            </v-tab-item>
            <v-tab-item>
              <EvaluationsComponent/>
            </v-tab-item>
            <v-tab-item>
              <TransversalPlanMaintainerHomeComponent/>
            </v-tab-item>
            <v-tab-item>
              <StrategyComponent/>
            </v-tab-item>
            <v-tab-item>
              <AdminReportsComponent/>
            </v-tab-item>
            <v-tab-item>
              <AdministrativeBasesMaintainerComponent/>
            </v-tab-item>
            <v-tab-item>
              <AdminApprovalFiles/>
            </v-tab-item>
            <v-tab-item>
              <ProjectManagementComponent/>
            </v-tab-item>
            <v-tab-item>
              <UsersManagementComponent/>
            </v-tab-item>
<!--            <v-tab-item>-->
<!--              <PlatformFormsManagerComponent/>-->
<!--            </v-tab-item>-->
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CostCenter from '@/components/CostCenter/CostCenter'
import Evaluations from "@/components/Evaluation/Evaluations";
import TransversalPlanMaintainerHome  from '@/components/TransversalPlans/TransversalPlanMaintainerHome';
import AdminReports from "@/components/AdminReports/AdminReports";
import StrategyMaintainer from "@/components/Strategies/StrategyMaintainer";
import AdministrativeBasesMaintainer from "@/components/AdministrativeBases/AdministrativeBasesMaintainer";
import AdminApprovalFiles from "@/components/ApprovalFiles/AdminApprovalFiles";
import ProjectManagement from "@/components/Project/ProjectManagment";
import UsersManagement from "@/components/Users/UsersManagement";
// import PlatformFormManager from "@/components/PlatformForms/PlatformFormHome.vue";
export default {
  components: {
    // 'PlatformFormsManagerComponent':PlatformFormManager,
    'TransversalPlanMaintainerHomeComponent': TransversalPlanMaintainerHome,
    'CostCenterComponent': CostCenter,
    'EvaluationsComponent': Evaluations,
    'AdminReportsComponent': AdminReports,
    'StrategyComponent': StrategyMaintainer,
    'AdministrativeBasesMaintainerComponent': AdministrativeBasesMaintainer,
    'AdminApprovalFiles': AdminApprovalFiles,
    'ProjectManagementComponent': ProjectManagement,
    'UsersManagementComponent': UsersManagement
  },
  data() {
    return {
      tabs: null,
    };
  },
};
</script>
<style scoped>
</style>

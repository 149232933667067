<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          Preguntas por Evaluaciones
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de preguntas por evaluaciones</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                color: white" dark class="mr-md-1">Crear Pregunta</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Preguntas</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            placeholder="Evaluaciones"
                            :items="evaluations"
                            :item-text="'name'"
                            :item-value="'id'"
                            icon="mdi-arrow-bottom-right-thin-circle-outline"
                            v-model="pregunta.evaluations"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="pregunta.name"
                            label="Preguntas"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
                  <v-btn v-if="pregunta.id === 0" color="blue darken-1" text @click="save()">Guardar</v-btn>
                  <v-btn v-else color="blue darken-1" text @click="update()">Actualizar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import Swal from "sweetalert2";
export default {
  name: "QuestionMaintainer",
  data(){
    return {
      rows: [],
      loading: false,
      dialog: false,
      evaluations: [],
      headers: [
        {
          text: 'Codigo',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Pregunta', value: 'name' },
        { text: 'Evaluacion', value: 'evaluation.name' },
        { text: 'Evaluacion Periodo', value: 'evaluation.period' },
        { text: "Acciones", value: 'acciones'}
      ],
      pregunta: {
        id: 0,
        name: "",
        evaluations: 0
      },
      defaultPregunta: {
        id: 0,
        name: "",
        evaluations: 0
      }
    }
  },
  methods: {
    ...mapActions('main', [
      'getAllEvaluation',
      'getAllQuestion',
      'createQuestion',
      'updateQuestion',
    ]),
    edit(item) {
      console.log(item);
      this.pregunta.id = item.id;
      this.pregunta.name = item.name;
      this.pregunta.evaluations = item.evaluation.id;
      this.dialog = true
    },
    getAllItemsEvaluation () {
      this.getAllEvaluation().then(response => {
        this.evaluations = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    getAllItemsQuestion () {
      this.getAllQuestion().then(response => {
        this.rows = response.data.response;
      }).catch(e => {
        console.log(e);
      });
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.pregunta = Object.assign({}, this.defaultPregunta)
      })
    },
    save () {
      this.loading=true;
      this.createQuestion(this.pregunta).then(response => {
        this.getAllItemsQuestion();
        Swal.fire({
          title: 'Crear Pregunta',
          text: 'Se ha creado la pregunta id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Crear Pregunta',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    update () {
      this.updateQuestion(this.pregunta).then(response => {
        this.getAllItemsQuestion();
        Swal.fire({
          title: 'Modificar Pregunta',
          text: 'Se ha modificado la pregunta id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Modificar Pregunta',
          text: e,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
  },
  async created(){
    await this.getAllItemsEvaluation();
    await this.getAllItemsQuestion();
  }
}
</script>

<style scoped>

</style>
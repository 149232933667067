<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col class="col-10 text-left">
          El centro de costo actualmente activo es el número {{info.ccValor}} con el nombre {{info.ccNombre}}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1"
          :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tabla con listado de Centros de Costos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                label="Buscador"
                placeholder="Buscador"
                class="mx-4"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{item}">
          <v-btn style="background-color: #EA7730;
                color: white" dark class="mr-md-1"
          @click="changeCC(item)">Activar</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'CostCenter',
  data() {
    return {
      search: "",
      headers: [
        { text: 'Centro de Costo', value: 'ccValor' },
        { text: 'Nombre', value: 'ccNombre' },
        { text: 'Tipo Unidad', value: 'tipoUnidad' },
        {text: 'Acciones', value: "acciones"},
      ],
      rows: [],
    };
  },
  created() {
    this.getCentrosCostos();
  },
  methods: {
    ...mapActions('main', [
      'getAllCentroCostos',
      'getAllCostCenter',
      'updateCC'
    ]),
    getCentrosCostos(){
      this.getAllCostCenter()
          .then(response => {
            this.rows = response.data.response;
          })
          .catch(err => (console.log(err)));
    },
    ...mapActions('authentication', [
      'setToken',
    ]),
    changeCC(item) {
      if (item.ccValor != null) {
        this.updateCC({
          email: this.fullTokenDecoded.sub,
          ccValor: item.ccValor.toString()
        })
            .then(response => {
              this.setToken(response.data.response)
                  .then(temp => console.log(temp.data.status));
            })
            .catch(err => (console.log(err)));
      }
    }
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    }),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
          this.$vuetify.breakpoint.md ||
          this.$vuetify.breakpoint.lg ||
          this.$vuetify.breakpoint.xl
      );
    },
  },
};
</script>
<style scoped>

</style>

<template>
  <div class="mb-8 my-16 py-6 px-4">
    <v-tabs v-model="tabs">
      <v-tab>Planes Transversales Mantenedor</v-tab>
      <v-tab>Ejes Estrategicos Asociados</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <TransversalPlanMaintainerComponent/>
      </v-tab-item>
      <v-tab-item>
        <TransversalPlanJoinStrategicAxisComponent/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TransversalPlanMaintainer from "./TransversalPlanMaintainer";
import TransversalPlanJoinStrategicAxis from "./TransversalPlanJoinStrategicAxis"
export default {
  name: "TransversalPlanMaintainerHome",
  components: {
    'TransversalPlanMaintainerComponent':TransversalPlanMaintainer,
    'TransversalPlanJoinStrategicAxisComponent': TransversalPlanJoinStrategicAxis
  },
  data(){
        return {
          tabs: null,
        }
      },
      methods: {

      },
  async mounted(){

  }
}
</script>

<style scoped>

</style>